import { Typography } from '@mui/material';
import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PortalConfig from 'app/api/models/PortalConfig';
import PageTitle from 'app/components/common/PageTitle';
import { Routes } from 'app/utils/constants';
import { isBrowser } from 'app/utils/misc';

const Page404Component: FC = () => {
  const { t } = useTranslation('Page404');

  if (isBrowser) {
    return (
      <>
        <PageTitle>{t('title')}</PageTitle>
        <Typography variant='h4'>{t('subTitle')}</Typography>
        <br />
        <Typography variant='body2'>
          <a target='_blank' href={`mailto:${PortalConfig.supportEmail || ''}`} rel='noreferrer'>
            {t('contactUs')}
          </a>{' '}
          {t('ifYouCantFind')}
        </Typography>
      </>
    );
  }
  return null;
};

export default Page404Component;
