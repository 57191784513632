import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

import Title from 'app/components/common/Title';

const PageTitle: React.FC<React.PropsWithChildren<{ children: ReactNode }>> = ({ children }) => {
  return (
    <PageTitleWrapper>
      <Title variant='h3'>{children}</Title>
    </PageTitleWrapper>
  );
};

export default PageTitle;

const PageTitleWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 0, 1, 0)};
`;
